<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Kontakt" />
        <ContactContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Layout/Navbar'
import PageTitle from '../components/Common/PageTitle'
import ContactContent from '../active-components/Contact/View'
import Footer from '../components/Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        ContactContent,
        Footer,
    }
}
</script>