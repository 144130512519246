<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row h-100">
                <div class="col-lg-4 col-md-6 col-sm-6 h-100">
                    <div class="contact-info-box h-100">
                        <div class="icon">
                            <i class="flaticon-email"></i>
                        </div>

                        <h3>E-Mail</h3>
                        <p><a href="mailto:info@cftools.software">info@cftools.software</a></p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 h-100">
                    <div class="contact-info-box h-100">
                        <div class="icon">
                            <i class="flaticon-marker"></i>
                        </div>

                        <h3>Standort</h3>
                        <p>
                          <a href="https://goo.gl/maps/DNkH7Shs9BZtT78i7" target="_blank">
                            CFTools Software GmbH<br>
                            Kiem-Pauli-Straße 8, 84036 Landshut
                          </a>
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3 h-100">
                    <div class="contact-info-box h-100">
                        <div class="icon">
                            <i class="flaticon-phone-call"></i>
                        </div>

                        <h3>Telefon</h3>
                        <p><a href="tel:+4987120549040">+49 871 205490-40</a></p>
                    </div>
                </div>
            </div>
            
            <div class="section-title">
                <span class="sub-title">Senden Sie uns eine Anfrage</span>
                <h2>Fragen?</h2>
                <p>
                  Senden Sie uns eine Anfrage und wir werden uns schnellstmöglich bei Ihnen melden.
                </p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="contact-image">
                        <img src="../../assets/images/contact.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="contact-form" v-if="!emitted">
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name" v-model="name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-Mail" v-model="email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Telefon" v-model="phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Unternehmen" v-model="company">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Ihre Nachricht" v-model="message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button class="default-btn" v-on:click="emitRequest()" :disabled="inProgress" :class="{'disabled': inProgress}">
                                      Absenden<span></span>
                                    </button>
                                    <div class="mt-1" v-if="error">
                                      <h6 class="small text-danger">
                                        Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut!
                                      </h6>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-else>
                      <h4>
                        Danke für Ihre Anfrage
                      </h4>
                      <h5>
                        Wir werden uns bei Ihnen demnächst melden!
                      </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactContent',
        methods: {
            async emitRequest() {
              if(!this.name.length || !this.email.length || !this.phone.length || !this.company.length || !this.message.length) return;
              this.inProgress = true;
              try {
                let payload = {
                  name: this.name,
                  email: this.email,
                  phone: this.phone,
                  company: this.company,
                  message: this.message
                };

                let url = new URL(`https://cfworkers.cftools.software/contact-form`);
                let response = await fetch(url, {
                  method: 'POST',
                  body: JSON.stringify(payload),
                  mode: 'no-cors'
                });

                if(response.status === 0) {
                  this.emitted = true;
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);

              } catch (error) {
                console.log(`[ERROR] ${error}`);
                this.error = true;
              }
              this.inProgress = false;
            }
        },
        data() {
          return {
            name: null,
            email: null,
            phone: null,
            company: null,
            message: null,
            error: false,
            emitted: false,
            inProgress: false
          };
        }
    }
</script>